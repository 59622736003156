import { getMinMax } from '../../utils/helpers'
import Panel from '../panel'
import { FormattedMessage } from 'react-intl'
import { ProjectPrice } from './project-price'
import { StaticMap } from './static-map'
import React from 'react'
import { useAgency } from '../../hooks/use-agency'
import { config } from '../../config'

export function ProjectSidebar({ project }) {
  const hasBedrooms = ['house', 'apartment'].includes(project.type)
  const hasArea = project.type === 'land'
  const hasGrossArea = ['office', 'commercial'].includes(project.type)

  return (
    <div className="o-container">
      <div className="o-grid">
        <div className="o-grid__item">
          <Panel
            title={<FormattedMessage id="project.panel.details.title" />}
            className="project-details p-0"
          >
            <div className="p-3">
              <h3>
                <ProjectPrice project={project} />
              </h3>
              <div className="flex">
                <div className="w-6/12">
                  <div>
                    {project.properties.length}{' '}
                    <FormattedMessage id="project.panel.details.units" />
                  </div>
                </div>
                <div className="w-6/12">
                  {hasBedrooms && <ProjectBedrooms project={project} />}
                  {hasArea && (
                    <ProjectArea project={project} areaType="plot_area" />
                  )}
                  {hasGrossArea && (
                    <ProjectArea project={project} areaType="gross_area" />
                  )}
                </div>
              </div>
              <div className="flex mt-3">
                <div className="w-6/12">
                  <div>
                    <ClosedUnitsPercent
                      units={project.properties}
                      mode="percentage"
                      negotiation={project.negotiation}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <StaticMap property={project} />
            </div>
          </Panel>
        </div>
      </div>
    </div>
  )
}

function ClosedUnitsPercent({ mode, units, negotiation }) {
  const closedCount = units.filter((unit) =>
    ['sold', 'rented'].includes(unit.attributes.status)
  ).length

  const total = units.length
  const value =
    mode === 'percentage' && total > 0
      ? Math.floor((closedCount / total) * 100)
      : closedCount

  return (
    <>
      {value}
      {mode == 'percentage' ? '% ' : ' '}
      {negotiation === 'sale' ? (
        <FormattedMessage id="project.rented-or-sold-units.sold" />
      ) : (
        <FormattedMessage id="project.rented-or-sold-units.rented" />
      )}
    </>
  )
}

function ProjectBedrooms({ project }) {
  const [min, max] = getBedroomsRange(project.properties)
  if (min == 0 && max === 0) {
    return null
  }

  return (
    <div>
      {min}
      {min !== max && ` - ${max}`} <FormattedMessage id="project.bedrooms" />
    </div>
  )
}

function ProjectArea({ project, areaType }) {
  const { settings } = useAgency()
  const [min, max, minUnit] = getAreaRange(project.properties, areaType)
  if (min == 0 && max === 0) {
    return null
  }
  const formatter = Intl.NumberFormat(
    config.numeral_system === 'decimal_comma' ? 'nl-BE' : 'en-UK',
    { maximumSignificantDigits: 3 }
  )

  return (
    <div>
      {formatter.format(min)}
      {min !== max && ` - ${formatter.format(max)} `}
      {minUnit.attributes.structure[areaType].units}
    </div>
  )
}

function getBedroomsRange(units) {
  return getMinMax(units, 'attributes.structure.bedrooms')
}

function getAreaRange(units, areaType) {
  return getMinMax(units, `attributes.structure.${areaType}.size`)
}
