import React from 'react'
import { AreaUnit } from '../../components/area-unit'
import Panel from '../panel'
import { useAgency } from '../../hooks/use-agency'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'

export function AreaPanel({ property }) {
  const { settings } = useAgency()
  if (property.internal_type === 'project') {
    return null
  }

  const rooms =
    property.attributes.structure.rooms?.filter((room) => {
      const size = parseFloat(room.size)
      if (isNaN(size)) {
        return false
      }
      if (size === 0) {
        return false
      }
      return true
    }) ?? []

  const areas = [
    property.attributes.structure.gross_area?.size
      ? {
          id: 'gross_area',
          size_description: 'gross_area',
          size: property.attributes.structure.gross_area.size,
          units: property.attributes.structure.gross_area.units,
        }
      : null,
    property.attributes.structure.plot_area?.size
      ? {
          id: 'plot_area',
          size_description: 'plot_area',
          size: property.attributes.structure.plot_area.size,
          units: property.attributes.structure.plot_area.units,
        }
      : null,
    property.attributes.structure.liveable_area?.size
      ? {
          id: 'liveable_area',
          size_description: 'liveable_area',
          size: property.attributes.structure.liveable_area.size,
          units: property.attributes.structure.liveable_area.units,
        }
      : null,
  ].filter(Boolean)

  if (rooms.length === 0 && areas.length === 0) {
    return null
  }

  const roomsByType = _.groupBy(rooms, 'type')

  const formatter = Intl.NumberFormat(
    `${settings.default_locale}-${settings.signup_country}`,
    { minimumFractionDigits: 0, maximumFractionDigits: 2 }
  )

  return (
    <Panel title={<FormattedMessage id="areas" />}>
      <div className="o-grid o-grid--gutter">
        <div className="o-grid__item">
          <ul className="c-feature-list c-feature-list--striped">
            {Object.keys(roomsByType).map((roomType) => {
              const rooms = roomsByType[roomType]
              return rooms.map((room, idx) => {
                return (
                  <li key={room.id} className="c-feature">
                    <div className="c-feature__description">
                      {roomType === 'custom' ? (
                        room.size_description
                      ) : (
                        <FormattedMessage id={`plurals.${roomType}.zero`} />
                      )}
                      {rooms.length > 1 &&
                        roomType !== 'custom' &&
                        ` ${idx + 1}`}
                    </div>
                    <div className="c-feature__info">
                      {formatter.format(room.size)}{' '}
                      <AreaUnit unit={room.units} />
                    </div>
                  </li>
                )
              })
            })}
            {areas.map((area) => {
              return (
                <li key={area.id} className="c-feature">
                  <div className="c-feature__description">
                    <FormattedMessage
                      id={area.size_description.replace('_', '-')}
                    />
                  </div>
                  <div className="c-feature__info">
                    {formatter.format(area.size)} <AreaUnit unit={area.units} />
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </Panel>
  )
}
