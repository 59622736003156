import React from 'react'
import Panel from '../panel'
import { FormattedMessage } from 'react-intl'
import { isPropertyInRegionWithPEB } from '../../utils/helpers'

export function EnergyPanel({ property }) {
  if (property.internal_type === 'project') {
    return null
  }

  const energyFeaturesGroups = ['energy', 'ecology', 'heating_cooling']
  const energyFeatures = energyFeaturesGroups.flatMap(
    (group) =>
      property.attributes.features?.[group]?.map(
        (feature) => `features.${group}.${feature}`
      ) ?? []
  )
  if (
    energyFeatures.length === 0 &&
    !property.attributes.legal?.energy?.epc_value &&
    !property.attributes.legal?.energy?.epc_category
  ) {
    return null
  }

  return (
    <Panel title={<FormattedMessage id="energy" />}>
      <div className="energy-panel__root">
        {energyFeatures.length > 0 && (
          <div className="energy-panel__features">
            <ul className="c-feature-list c-feature-list--checked c-feature-list--split-2">
              {energyFeatures.map((featureId) => {
                return (
                  <li key={featureId} className="c-feature">
                    <span className="c-feature__description">
                      <FormattedMessage id={featureId} />
                    </span>
                  </li>
                )
              })}
            </ul>
          </div>
        )}

        {property.attributes.legal?.energy?.epc_value ||
        property.attributes.legal?.energy?.epc_category ||
        property.attributes.legal?.energy?.dpe ||
        property.attributes.legal?.energy?.greenhouse_emissions ? (
          <div className="energy-panel__energy">
            {/* dpe (EN="DPE") enum of A, B, C, D, E, F, G */}
            {property.attributes.legal?.energy?.dpe && (
              <div className="c-key-number">
                <div className="c-key-number__value">
                  {property.attributes.legal?.energy?.dpe}
                </div>
                <div className="c-key-number__label">
                  <FormattedMessage id="legal.energy.dpe.label" />
                </div>
              </div>
            )}
            {/* END dpe */}

            {/* epc_value (EN="Energy Score") */}
            {property.attributes.legal?.energy?.epc_value && (
              <div className="o-grid__item u-2-of-4 c-key-number">
                <div className="c-key-number__value">
                  {property.attributes.legal?.energy?.epc_value}{' '}
                  <small>kWh/m²</small>
                </div>
                <div className="c-key-number__label">
                  <FormattedMessage id="legal.energy.epc_value.label" />
                </div>
              </div>
            )}
            {/* END epc_value */}

            {/* epc_category (EN="EPC Level") enum of A++, A+, A, B, C, D, E, F, G */}
            {property.attributes.legal?.energy?.epc_category && (
              <div className="o-grid__item u-2-of-4 c-key-number">
                {isPropertyInRegionWithPEB(property) ? (
                  <div className="c-key-number__value">
                    <img
                      src={`/img/peb_logos/peb_${property.attributes.legal?.energy?.epc_category
                        ?.toLowerCase()
                        ?.replace(/\+/g, 'plus')}.png`}
                      alt={property.attributes.legal?.energy?.epc_category}
                      height="20"
                    />
                  </div>
                ) : (
                  <div className="c-key-number__value">
                    {property.attributes.legal?.energy?.epc_category}
                  </div>
                )}
                <div className="c-key-number__label">
                  <FormattedMessage id="legal.energy.epc_category.label" />
                </div>
              </div>
            )}
            {/* END epc_category */}

            {/* greenhouse_emissions */}
            {property.attributes.legal?.energy?.greenhouse_emissions && (
              <div className="o-grid__item u-2-of-4 c-key-number">
                <div className="c-key-number__value">
                  {property.attributes.legal?.energy?.greenhouse_emissions}{' '}
                </div>
                <div className="c-key-number__label">
                  <FormattedMessage id="legal.energy.greenhouse_emissions.label" />
                </div>
              </div>
            )}
            {/* END greenhouse_emissions */}
          </div>
        ) : null}
      </div>
    </Panel>
  )
}
