import React from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { useFlags } from 'gatsby-plugin-launchdarkly'
import Link from '../localized-link'
import Negotiator from './negotiator'

export function PropertySidebar({ property }) {
  const flags = useFlags()
  const showVisit =
    flags.integrationsCalendar && property.appointment_service_url

  return (
    <>
      <div className="o-container">
        <div className="o-grid">
          <div className="o-grid__item">
            <Negotiator property={property} />
          </div>
        </div>
      </div>
      <div className="o-container print-display-none">
        <section className="o-grid o-grid--gutter">
          <div className="o-grid__item">
            <p
              className={classNames({
                'u-mb-alpha': showVisit,
              })}
            >
              <Link
                state={{ propertyId: property.id }}
                to="/contact"
                className="c-button c-button--alpha c-button--md c-button--block"
                onClick={() => {
                  // eslint-disable-next-line
                  window.analytics?.track('Contact', {
                    propertyId: property.id,
                  })
                }}
              >
                <FormattedMessage id="contact-me" />
              </Link>
            </p>
            {showVisit && (
              <p>
                <Link
                  to="/schedule-visit"
                  state={{
                    propertyId: property.id,
                    appointmentServiceUrl: property.appointment_service_url,
                  }}
                  className="c-button c-button--neutral c-button--md c-button--block"
                >
                  <FormattedMessage id="schedule-visit.action-button" />
                </Link>
              </p>
            )}
          </div>
        </section>
      </div>
    </>
  )
}
