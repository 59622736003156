import React from 'react'
import Panel from '../panel'
import _ from 'lodash'
import { FeatureItem } from '../feature'
import { FormattedMessage } from 'react-intl'
import { formatMoneyAmount } from '../../utils/helpers'

export function LegalMentionsPanel({ property, locale }) {
  if (property.internal_type === 'project') {
    return null
  }

  const {
    epc_reference,
    e_level,
    total_epc_value,
    co2_emissions,
    report_fuel_tank,
    report_electricity_gas,
  } = property.attributes.legal?.energy ?? {}

  const regulations = property.attributes.legal?.regulations || []
  const { flood_risk, land_use_designation, cadastral_income } =
    property.attributes.legal?.property_and_land ?? {}

  const sections = [
    e_level && (
      <FeatureItem
        description={<FormattedMessage id="legal.energy.e_level.label" />}
        info={e_level}
      />
    ),
    co2_emissions && (
      <FeatureItem
        description={<FormattedMessage id="legal.energy.co2_emissions.label" />}
        info={`${co2_emissions} kg`}
      />
    ),
    epc_reference && (
      <FeatureItem
        description={<FormattedMessage id="legal.energy.epc_reference.label" />}
        info={epc_reference}
      />
    ),
    total_epc_value && (
      <FeatureItem
        description={
          <FormattedMessage id="legal.energy.total_epc_value.label" />
        }
        info={`${total_epc_value} kWh`}
      />
    ),
    report_fuel_tank && (
      <FeatureItem
        description={
          <FormattedMessage id="legal.energy.report_fuel_tank.label" />
        }
        info={
          <FormattedMessage
            id={`legal.energy.report_fuel_tank.value.${report_fuel_tank}`}
          />
        }
      />
    ),
    report_electricity_gas && (
      <FeatureItem
        description={
          <FormattedMessage id="legal.energy.report_electricity_gas.label" />
        }
        info={
          <FormattedMessage
            id={`legal.energy.report_electricity_gas.value.${report_electricity_gas}`}
          />
        }
      />
    ),
    ...(regulations?.map(({ name, value }) => {
      return (
        <li className="c-feature" key={name}>
          <span className="c-feature__description">
            <FormattedMessage id={`legal.regulations.${name}.label`} />
          </span>
          <span className="c-feature__info">
            {value ? (
              <FormattedMessage id="legal.value.true" />
            ) : (
              <FormattedMessage id="legal.value.false" />
            )}
          </span>
        </li>
      )
    }) ?? []),
    flood_risk && (
      <FeatureItem
        description={
          <FormattedMessage id="legal.property_and_land.flood_risk.label" />
        }
        info={
          <FormattedMessage
            id={`legal.property_and_land.flood_risk.value.${flood_risk}`}
          />
        }
      />
    ),
    !isNaN(cadastral_income) && (
      <FeatureItem
        description={
          <FormattedMessage id="legal.property_and_land.cadastral_income.label" />
        }
        info={formatCadastralIncome(cadastral_income)}
      />
    ),
    land_use_designation && (
      <FeatureItem
        description={
          <FormattedMessage id="legal.property_and_land.land_use_designation.label" />
        }
        info={
          <FormattedMessage
            id={`legal.property_and_land.land_use_designation.value.${land_use_designation}`}
          />
        }
      />
    ),
  ].filter(Boolean)

  if (sections.length === 0) {
    return null
  }

  return (
    <Panel title={<FormattedMessage id="legal-mentions" />}>
      <div className="o-grid o-grid--gutter">
        <div className="o-grid__item u-4-of-4-bp4">
          <ul className="c-feature-list c-feature-list--striped">{sections}</ul>
        </div>
      </div>
    </Panel>
  )
}

function formatCadastralIncome(amount) {
  // we harcode the currency here
  return formatMoneyAmount({
    amount,
    currency: 'EUR',
  })
}
