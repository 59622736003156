import React from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import Panel from '../panel'
import { PropertyPrice } from './property-price'

const PriceAndCondition = ({ property }) => {
  if (property.internal_type === 'project') {
    return null
  }

  const propertyType = property.type
  const conditions =
    propertyType === 'land'
      ? ['poor', 'fair', 'good']
      : ['poor', 'fair', 'good', 'mint', 'new']
  const currentCondition = property.attributes.general_condition

  return (
    <Panel
      title={
        <FormattedMessage
          id={
            property.negotiation === 'let'
              ? 'rent-and-condition'
              : 'price-and-condition'
          }
        />
      }
    >
      <div className="o-grid o-grid--gutter o-grid--spaced-vertical flex flex-wrap">
        <div className="o-grid__item" style={{ flex: '1 200px' }}>
          <h3 className="panel__subtitle">
            {property.negotiation == 'sale' ? (
              <FormattedMessage id="price" />
            ) : (
              <FormattedMessage id="price_let" />
            )}
          </h3>
          <p>
            <PropertyPrice property={property} />
          </p>
        </div>
        <div className="o-grid__item flex-shrink-0" style={{ flex: '1 400px' }}>
          <h3 className="panel__subtitle">
            <FormattedMessage id="current-condition" />
          </h3>
          <div
            className={classNames('c-scale', {
              'c-scale--land': propertyType == 'land',
            })}
          >
            {conditions.map((condition) => {
              return (
                <div
                  className={classNames('c-scale__label', {
                    'c-scale__label--active': currentCondition === condition,
                  })}
                >
                  <FormattedMessage id={`conditions.${condition}`} />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Panel>
  )
}

export default PriceAndCondition
